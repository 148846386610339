import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppThunkDispatch } from '../../redux/hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import Button from 'app/core/shared-components/button';
import ConfirmationModal from 'app/core/shared-components/confirmation-modal';
import RowCreator from 'app/pages/Panel/components/rowCreator';
import ButtonGroup from 'app/core/shared-components/button-group';
import ClickAwayListener from 'app/core/shared-components/click-away';
import ImageComponent from 'app/core/shared-components/image';
import Search from 'app/core/shared-components/search';
import Drawer from 'app/core/shared-components/drawer';
import Loader from 'app/core/shared-components/loader';
import routeError from 'router/routeError';
import ErrorPage from 'app/pages/Error';
import Filters from 'app/pages/Panel/components/filters';
import ButtonGroups from 'app/pages/Panel/components/utils/ButtonGroups';
import { ISelectValue } from 'app/core/shared-components/select';
import CustomSelect, { ISelectOptions } from 'app/core/shared-components/customSelect';
import { IClippedSelectValue } from 'app/core/shared-components/clipped-select';
import GetLDeleteModalEdgeCase from 'app/core/shared-components/users-section-tab/utils';
import PanelDrawer from './components/utils/PanelDrawer';
import ToastUndo from 'app/core/shared-components/toast-undo';
import Table from 'app/core/shared-components/table';
import Typography from 'app/core/shared-components/typography';
import SortingOptions from 'app/core/shared-components/sorting-otions';
import { markOnBenchService, deletePanelService } from 'app/services/panel.service';
import { notify } from 'helpers/toastHelper';
import { ReactComponent as LoaderSpinner } from 'assets/images/loader-spinner.svg';
import { getEmployeeCount } from 'app/redux/slices/adminDashboardSlice';
import deleteIcon from 'assets/images/delete.svg';
import { normalStyle } from 'app/pages/Dashboard/components/slot-efficiency-graph/techLevel.style';
import { getExperience, handleMapFunction, handleTabEventwithoutValue } from 'helpers/utils';
import Images from 'assets/images';
import {
  getInReviewPanel,
  getArchivedPanel,
  getApprovedPanel,
  getSearchedData,
  getSelectedUserData,
  editUserInfo,
  setLoading,
  approvePanel,
  archievePanel,
  setCurrentDeletion
} from 'app/redux/slices/panelSlice';
import {
  APPROVE_TAB,
  NAME_ASC,
  EXP_ASC,
  EXP_DESC,
  NAME_DESC,
  IN_REVIEW_TAB,
  NAME_A_to_Z,
  NAME_Z_to_A,
  EXP_L_TO_H,
  EXP_H_TO_L,
  RECENTLY_ADDED,
  NAME,
  EXP,
  CANCEL,
  DELETE,
  DELETE_PANEL,
  TECH,
  MEDIUM,
  OUTLINED,
  CONTAINED,
  PANEL_SEARCH_PLACEHOLDER,
  STATUS,
  PROJECT_TAB,
  ARCHIEVED_TAB,
  STATUSENUM_APPROVED,
  STATUSENUM_IN_REVIEW,
  RECENTY_ADDED_PANEL,
  APPROVE_BTN_TEXT,
  MASS_ONBOARDING_HEADING,
  ONBOARDING_HEADING,
  ROLE_ADMIN,
  ALL_LABEL,
  ON_PROJECT_DRPDWN,
  VIEW_DETAILS,
  APPROVED_TAB,
  ARCHIEVED_TAB_VALUE,
  ALL,
  ON_PROJECT,
  ON_BENCH_PROJECT
} from 'helpers/constants';
import {
  IFilters,
  IPanelData,
  IPanelFilters,
  IPanelStates,
  IPanelStatesData,
  IPanelType,
  IProjectStatusType,
  IProjectType,
  ISelectedPanelType,
  ITechStackType,
  projectStatusOptions,
  statusOptions
} from 'helpers/panel';
import {
  APPROVAL_CONFIRMATION,
  APPROVE_USER_MODAL_TITLE,
  DELETE_CONFIRMATION_TEXT,
  DELETE_SINGLE_USER,
  SUCCESSFULLY_APPROVED,
  SUCCESSFULLY_ARCHIEVED,
  SUCCESSFULLY_ONBOARDED,
  SUCCESSFULLY_DELETED,
  APPROVAL_MSG_1,
  APPROVAL_MSG_2,
  SUCCESSFULLY_APPROVED_USERS,
  ON_BENCH_LABEL,
  ON_BENCH_USER_TEXT
} from 'helpers/messages.constants';
import './index.style.scss';
import { ITechStackArray } from 'app/core/shared-components/form';

interface IhandleTabChangeArgs {
  tab: number;
  pgNos?: number;
  sortOps?: string;
  emptySelectedRows?: boolean;
}
function Panel() {
  const [panelState, setPanelState] = useState<IPanelStates>(IPanelStatesData);
  const [firstStatusOption, ...rest] = statusOptions;
  const [statusValue, setStatusValue] = useState(firstStatusOption);
  const [firstProjectOption, ...restData] = projectStatusOptions;
  const [projectStatusValue, setProjectStatusValue] = useState<ISelectOptions>(firstProjectOption);
  const [approveStatusModal, setApproveStatusModal] = useState(false);
  const [approveModalData, setApproveModalData] = useState('');
  const [approveUser, setApproveUser] = useState({ id: '', firstName: '', lastName: '' });
  const [nonDeletedUserModal, setNonDeletedUserModal] = useState(false);
  const [nonDeletedUserArray, setNonDeletedUserArray] = useState();
  const [multiOnboard, setMultiOnboard] = useState(false);
  const [hideArchive, setHideArchive] = useState(false);
  const [isNoFilterScreen, setNoFilterScreen] = useState(false);
  const userState = useAppSelector((state) => state.user);
  const panelData = useAppSelector((state) => state.panel.panelData);
  const { recruiter, panel } = useAppSelector((state) => state.shared);
  const { shared } = useAppSelector((state) => state);
  const { userId: id } = useAppSelector((state) => state.user.userData);
  const timeOut = useRef<any>(null);
  const abortSignal = useRef<any>(null);
  const thunkDispatch = useAppThunkDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [openStatusDrawer, setOpenStatusDrawer] = useState(location);
  const [tabButtonValue, setTabButtonValue] = useState<number>(0);
  const {
    selectedRows,
    isFilteringOn,
    filterCount,
    isSortingOn,
    sortingValue,
    isDeleteOn,
    clearResult,
    searchedOptions,
    selectedUser,
    filtersBody,
    isEditingOn,
    isSingleDeleteOn,
    noResultFound,
    filters,
    isSearchOptionSelected
  } = panelState;
  const { sortIcon, filter } = Images;
  const lowestExp = [0, 2, 4, 8, 12];
  const highestExp = [2, 4, 8, 12, 50];

  async function onSearchChange(searchString: string) {
    setPanelState((prevState) => ({
      ...prevState,
      searchedOptions: [],
      isSortingOn: false,
      isFilteringOn: false
    }));
    if (panelState.noResultFound) {
      setPanelState((prevState) => ({
        ...prevState,
        noResultFound: false
      }));
    }

    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    if (!!searchString.trim().length) {
      timeOut.current = setTimeout(async () => {
        if (abortSignal.current) abortSignal.current.abort();
        abortSignal.current = new AbortController();
        const signal = abortSignal.current.signal;
        const data = await thunkDispatch(getSearchedData({ searchString, signal })).unwrap();
        if (!data?.error) {
          const options = data?.data?.map((panel: IPanelData) => {
            return {
              startIcon: panel.profileImageUrl,
              searchKey: `${panel.firstName} ${panel.lastName}`,
              id: panel.id,
              supportingData: `${panel.firstName} ${panel.lastName}`,
              singleSearchData: panel
            };
          });
          setPanelState((prevState) => ({
            ...prevState,
            searchedOptions: options,
            clearResult: false
          }));
        } else {
          setPanelState((prevState) => ({
            ...prevState,
            searchedOptions: [],
            noResultFound: true
          }));
        }
      }, 1000);
    } else {
      setPanelState((prevState) => ({ ...prevState, searchedOptions: [] }));
    }
  }

  function generateRole(array: IPanelType[]) {
    const roles: IPanelType[] = array?.filter(
      (role) => role?.id == recruiter?.id || role?.id == panel?.id
    );
    const [firstRole, ...rest] = roles;
    const currentRole = firstRole?.id === recruiter?.id ? recruiter : panel;
    return currentRole;
  }

  async function getPanelSpecific(filters: IPanelFilters) {
    setPanelState((prevState) => ({
      ...prevState,
      searchedOptions: [],
      currentPg: panelState.pageNo
    }));
    thunkDispatch(setLoading(true));
    switch (panelState.currentListType) {
      case APPROVE_TAB:
        thunkDispatch(setLoading(true));
        await thunkDispatch(
          getApprovedPanel({
            pgNo: panelState.pageNo - 1,
            filters: filters
          })
        );
        thunkDispatch(setLoading(false));
        break;
      case ARCHIEVED_TAB:
        thunkDispatch(setLoading(true));
        await thunkDispatch(
          getArchivedPanel({
            pgNo: panelState.pageNo - 1,
            filters: filters
          })
        );
        thunkDispatch(setLoading(false));
        break;
      default:
        thunkDispatch(setLoading(true));
        await thunkDispatch(
          getInReviewPanel({
            pgNo: panelState.pageNo - 1,
            filters: filters
          })
        );
        thunkDispatch(setLoading(false));
    }
    thunkDispatch(setLoading(false));
  }
  async function commonTdClick(id: string) {
    thunkDispatch(setLoading(true));
    const user = await thunkDispatch(getSelectedUserData(id.toString())).unwrap();
    const tempRole = generateRole(user.data.roles || []);
    const tempProject = user.data.projects?.map((item: IProjectType) => ({
      id: item?.id,
      label: item?.name
    }));
    const tempStackLoi = handleMapFunction(user?.data?.techStackInterviewLevelDtos, true, 2);
    const temptechStack = handleMapFunction(user?.data?.techStackInterviewLevelDtos, false, 3);
    setPanelState((prevState) => ({
      ...prevState,
      formData: {
        role: tempRole,
        experience: user.data.totalExperience && getExperience(user.data.totalExperience),
        projects: [...tempProject],
        stackLoi: [...tempStackLoi],
        techStack: [...temptechStack]
      },
      isEditingOn: true,
      selectedRows: [],
      isSortingOn: false,
      isFilteringOn: false,
      selectedUser: user?.data
    }));
    thunkDispatch(setLoading(false));
  }

  async function markOnBench() {
    const status =
      projectStatusValue.label === ALL_LABEL
        ? ALL?.toUpperCase()
        : projectStatusValue.label === ON_PROJECT_DRPDWN
        ? ON_PROJECT
        : ON_BENCH_PROJECT;
    thunkDispatch(setLoading(true));
    await markOnBenchService(panelState?.selectedRows);
    getPanelSpecific({ ...panelState?.filtersBody, panelStatusEnum: status });
    setPanelState((prevState) => ({
      ...prevState,
      isDeleteOn: false,
      selectedRows: [],
      isEditingOn: false,
      isSingleDeleteOn: false,
      isToastVisible: true
    }));
    notify(
      true,
      <>
        {
          <div className="toast-container-detail">
            <Typography customStyle="toast-title">
              {`${ON_BENCH_LABEL} ${selectedRows?.length} ${ON_BENCH_USER_TEXT}`}
            </Typography>
          </div>
        }
      </>,
      uuidv4().toString(),
      blankFunc
    );
    thunkDispatch(setLoading(false));
  }

  function setSearchedOption(id: number | string, singleSearchData: null) {
    commonTdClick(id.toString());
    setPanelState((prevState) => ({
      ...prevState,
      clearResult: false,
      isSearchOptionSelected: true
    }));
  }
  function localDelete(panel: ISelectedPanelType | any[]) {
    let temp = panelState?.panelDataRecords;
    if (Array.isArray(panel)) {
      temp = temp?.filter((val: IPanelData) => !panel.includes(val?.id));
    } else {
      temp = temp?.filter((item: IPanelData) => item.id !== panel?.userId);
    }
    setPanelState((prevState) => ({
      ...prevState,
      isDeletionInProcess: true,
      isDeleteOn: false,
      isSingleDeleteOn: false,
      isSearchedOptionSelected: false,
      clearResult: true,
      searchText: '',
      panelDataRecords: temp,
      isToastVisible: true
    }));
    let panelId: string = '';
    if (Array.isArray(panel)) {
      const [firstPanel, ...rest] = panel;
      panelId = firstPanel;
    }
    const toastDataID = Array.isArray(panel) ? panelId : panel?.userId;
    notify(
      false,
      <ToastUndo
        toastMessage={`${SUCCESSFULLY_DELETED} ${
          selectedRows.length < 1 ? selectedUser?.firstName + ' ' + selectedUser?.lastName : 'users'
        }`}
        handleUndo={onUndoDelete}
        toastId={toastDataID}
      />,
      toastDataID,
      deletePanelData
    );
    thunkDispatch(setCurrentDeletion(panel));
  }
  function deleteConfirmationClick() {
    const ids = selectedRows.length > 1 ? selectedRows : panelState?.selectedUser;
    localDelete(ids);
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: false
    }));
  }

  async function deletePanelData() {
    const ids = selectedRows?.length > 1 ? selectedRows : [selectedUser?.userId];
    const status =
      projectStatusValue.label === ALL_LABEL
        ? ALL?.toUpperCase()
        : projectStatusValue.label === ON_PROJECT_DRPDWN
        ? ON_PROJECT
        : ON_BENCH_PROJECT;
    thunkDispatch(setLoading(true));
    const deleteSuccessFul = await deletePanelService(ids);
    if (deleteSuccessFul.data) {
      getPanelSpecific({ ...panelState?.filtersBody, panelStatusEnum: status });
      setPanelState((prevState) => ({
        ...prevState,
        isDeleteOn: false,
        selectedRows: [],
        isEditingOn: false,
        isSingleDeleteOn: false,
        clearResult: false,
        isSearchOptionSelected: false
      }));
      const userRole = userState.userData.role.map((item) => item.role).includes(ROLE_ADMIN);
      userRole && thunkDispatch(getEmployeeCount());
    } else {
      setPanelState((prevState) => ({
        ...prevState,
        isDeleteOn: false,
        selectedRows: [],
        isEditingOn: false,
        isSingleDeleteOn: false,
        isMultiDeleteOn: false,
        isSearchOptionSelected: false
      }));
      setNonDeletedUserModal(true);
      const [err, ...rest] = deleteSuccessFul?.error;
      setNonDeletedUserArray(err?.data);
    }
    thunkDispatch(setLoading(false));
  }

  function applyFilters(param: IFilters) {
    let status =
      projectStatusValue.label === ALL_LABEL
        ? ALL?.toUpperCase()
        : projectStatusValue.label === ON_PROJECT_DRPDWN
        ? ON_PROJECT
        : ON_BENCH_PROJECT;
    thunkDispatch(setLoading(true));
    let expFrom = 0,
      expTo = Math.max(...param.experience);
    if (param.experience.length) {
      expFrom = lowestExp[Math.min(...param.experience)];
      expTo = highestExp[Math.max(...param.experience)];
    }
    let filterBody = {
      experience: [
        { experienceFrom: expFrom > 0 ? expFrom : 0, experienceTo: expTo > 0 ? expTo : 50 }
      ],
      techStack: param.techStacks.length ? param.techStacks : null,
      levelOfPanel: param.interviewLevelList.length ? param.interviewLevelList : null,
      projects: param.projects.length ? param.projects : null,
      sortBy: panelState.filtersBody.sortBy,
      eligiblePanels: param?.eligiblePanels,
      panelStatusEnum: param.projects.length ? ALL?.toUpperCase() : status
    };
    setProjectStatusValue(param.projects.length ? firstProjectOption : projectStatusValue);
    setNoFilterScreen(param?.count > 0 ? true : false);
    getPanelSpecific(filterBody);
    setPanelState((prevState) => ({
      ...prevState,
      filterCount: param.count,
      filters: param,
      filtersBody: filterBody,
      currentPg: 1,
      pageNo: 1
    }));
    thunkDispatch(setLoading(false));
  }
  const handleTabChange = (args: IhandleTabChangeArgs) => {
    const { tab, pgNos, sortOps, emptySelectedRows = true } = args;
    setTabButtonValue(tab);
    handlCloseDrawer();
    let pageNum = pgNos === 0 ? pgNos : panelState.currentPg - 1;
    if (!panelState.isSearchOptionSelected) {
      switch (parseInt(tab.toString())) {
        case 1:
          thunkDispatch(setLoading(true));
          thunkDispatch(
            getApprovedPanel({
              pgNo: pageNum,
              filters: sortOps
                ? { ...panelState.filtersBody, sortBy: sortOps }
                : panelState.filtersBody
            })
          );
          thunkDispatch(setLoading(false));
          setPanelState((prevState) => ({
            ...prevState,
            currentListType: APPROVE_TAB,
            currentPg: pgNos === 0 ? 1 : panelState.currentPg,
            selectedRows: emptySelectedRows ? [] : prevState.selectedRows,
            isSortingOn: false,
            isFilteringOn: false,
            isEditingOn: false
          }));
          break;
        case 2:
          thunkDispatch(setLoading(true));
          thunkDispatch(
            getArchivedPanel({
              pgNo: pageNum,
              filters: sortOps
                ? { ...panelState.filtersBody, sortBy: sortOps }
                : panelState.filtersBody
            })
          );
          thunkDispatch(setLoading(false));
          setPanelState((prevState) => ({
            ...prevState,
            currentListType: ARCHIEVED_TAB,
            currentPg: pgNos === 0 ? 1 : panelState.currentPg,
            selectedRows: emptySelectedRows ? [] : prevState.selectedRows,
            isSortingOn: false,
            isFilteringOn: false,
            isEditingOn: false
          }));
          break;
        default:
          thunkDispatch(setLoading(true));
          thunkDispatch(
            getInReviewPanel({
              pgNo: pageNum,
              filters: sortOps
                ? { ...panelState.filtersBody, sortBy: sortOps }
                : panelState.filtersBody
            })
          );
          thunkDispatch(setLoading(false));
          setPanelState((prevState) => ({
            ...prevState,
            currentListType: IN_REVIEW_TAB,
            currentPg: pgNos === 0 ? 1 : panelState.currentPg,
            selectedRows: emptySelectedRows ? [] : prevState.selectedRows,
            isSortingOn: false,
            isFilteringOn: false,
            isEditingOn: false
          }));
          break;
      }
    }
  };
  async function handleButtonGroupClick(possition: number) {
    setTabButtonValue(possition);
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: false,
      isSearchOptionSelected: false,
      searchedOptions: [],
      clearResult: true
    }));
    thunkDispatch(setLoading(true));
    handleTabChange({ tab: possition, pgNos: 0 });
    thunkDispatch(setLoading(false));
  }

  function selectSort(val: string) {
    setPanelState((prevState) => ({ ...prevState, sortingValue: val }));
    let temp = [...panelState?.panelDataRecords];
    switch (val) {
      case NAME_A_to_Z:
        setPanelState((prevState) => ({
          ...prevState,
          filtersBody: { ...prevState.filtersBody, sortBy: NAME_ASC }
        }));
        break;
      case NAME_Z_to_A:
        setPanelState((prevState) => ({
          ...prevState,
          filtersBody: { ...prevState.filtersBody, sortBy: NAME_DESC }
        }));
        break;
      case EXP_L_TO_H:
        setPanelState((prevState) => ({
          ...prevState,
          filtersBody: { ...prevState.filtersBody, sortBy: EXP_ASC }
        }));
        break;
      case EXP_H_TO_L:
        setPanelState((prevState) => ({
          ...prevState,
          filtersBody: { ...prevState.filtersBody, sortBy: EXP_DESC }
        }));
        break;
      case RECENTLY_ADDED:
        setPanelState((prevState) => ({
          ...prevState,
          filtersBody: { ...prevState.filtersBody, sortBy: RECENTY_ADDED_PANEL }
        }));
        break;
      default:
        temp = temp;
        break;
    }
  }
  const handleProjectStatusChange = (params: ISelectOptions) => {
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: false,
      isSearchOptionSelected: false,
      searchedOptions: [],
      clearResult: true,
      filtersBody: { ...prevState.filtersBody, panelStatusEnum: status }
    }));
    setProjectStatusValue(params);
    const status =
      params?.label === ALL_LABEL
        ? ALL?.toUpperCase()
        : params?.label === ON_PROJECT_DRPDWN
        ? ON_PROJECT
        : ON_BENCH_PROJECT;

    thunkDispatch(setLoading(true));
    switch (tabButtonValue) {
      case 1:
        thunkDispatch(setLoading(true));
        thunkDispatch(
          getApprovedPanel({
            pgNo: 0,
            filters: { ...panelState.filtersBody, panelStatusEnum: status }
          })
        ).then((response) => setNoFilterScreen(response?.payload?.data?.totalCount ? false : true));
        thunkDispatch(setLoading(false));
        break;
      case 2:
        thunkDispatch(setLoading(true));
        thunkDispatch(
          getArchivedPanel({
            pgNo: 0,
            filters: { ...panelState.filtersBody, panelStatusEnum: status }
          })
        ).then((response) => setNoFilterScreen(response?.payload?.data?.totalCount ? false : true));
        thunkDispatch(setLoading(false));
        break;
      default:
        thunkDispatch(setLoading(true));
        thunkDispatch(
          getInReviewPanel({
            pgNo: 0,
            filters: { ...panelState.filtersBody, panelStatusEnum: status }
          })
        ).then((response) => setNoFilterScreen(response?.payload?.data?.totalCount ? false : true));
        thunkDispatch(setLoading(false));
        break;
    }
    thunkDispatch(setLoading(false));
  };
  const handleApproveModalOpen = (data: IPanelData, e?: IProjectStatusType) => {
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: false,
      isSearchOptionSelected: false,
      searchedOptions: [],
      clearResult: true
    }));
    setApproveUser(data);
    setApproveStatusModal(e?.id === 1 ? false : true);
    const userConfirmationText =
      selectedRows.length > 1
        ? APPROVAL_CONFIRMATION
        : `${APPROVAL_MSG_1} ${data?.firstName} ${data?.lastName} ${APPROVAL_MSG_2}`;
    setApproveModalData(userConfirmationText);
    setStatusValue(firstStatusOption);
  };

  const handleCloseApproveModal = () => {
    setApproveStatusModal(false);
    setPanelState((prevState) => ({
      ...prevState,
      isDeleteOn: false,
      selectedRows: [],
      isEditingOn: false,
      isSingleDeleteOn: false
    }));
    setStatusValue(firstStatusOption);
  };
  const blankFunc = () => {};
  const handleNavigateTab = (tab: number, sortOption?: string, panelData?: string) => () => {
    handleTabChange({ tab: tab, pgNos: 0, sortOps: sortOption });
    panelData && commonTdClick(panelData);
  };
  async function handleApproveStatus() {
    const ids = selectedRows.length > 1 ? selectedRows : [approveUser?.id];
    thunkDispatch(setLoading(true));
    await thunkDispatch(approvePanel(ids));
    notify(
      true,
      <>
        {
          <div className="toast-container-detail">
            <Typography customStyle="toast-title">
              {selectedRows?.length > 1
                ? { SUCCESSFULLY_APPROVED_USERS }
                : `${SUCCESSFULLY_APPROVED} ${approveUser?.firstName} ${approveUser?.lastName}`}
            </Typography>
            <Button
              variant="outlined"
              customStyle="toast-detail-button"
              onClick={handleNavigateTab(1, RECENTY_ADDED_PANEL, approveUser?.id)}>
              {VIEW_DETAILS}
            </Button>
          </div>
        }
      </>,
      uuidv4().toString(),
      blankFunc
    );
    handleTabChange({ tab: tabButtonValue });
    setPanelState((prevState) => ({
      ...prevState,
      isDeleteOn: false,
      selectedRows: [],
      isEditingOn: false,
      isSingleDeleteOn: false,
      isToastVisible: true,
      clearResult: true,
      isSearchOptionSelected: false
    }));
    setApproveStatusModal(false);
    thunkDispatch(setLoading(false));
  }

  const handlCloseDrawer = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: false,
      clearResult: true,
      isSearchOptionSelected: false
    }));
    setHideArchive(false);
    setMultiOnboard(false);
  };
  async function handleArchiveUsers() {
    const status =
      projectStatusValue.label === ALL_LABEL
        ? ALL?.toUpperCase()
        : projectStatusValue.label === ON_PROJECT_DRPDWN
        ? ON_PROJECT
        : ON_BENCH_PROJECT;
    thunkDispatch(setLoading(true));
    await thunkDispatch(archievePanel(selectedRows));
    getPanelSpecific({ ...panelState?.filtersBody, panelStatusEnum: status });
    setPanelState((prevState) => ({
      ...prevState,
      isDeleteOn: false,
      selectedRows: [],
      isEditingOn: false,
      isSingleDeleteOn: false,
      isToastVisible: true
    }));
    notify(
      true,
      <>
        {
          <div className="toast-container-detail">
            <Typography customStyle="toast-title">
              {`${SUCCESSFULLY_ARCHIEVED} ${selectedRows?.length} users.`}
            </Typography>
            <Button
              variant="outlined"
              customStyle="toast-detail-button"
              onClick={handleNavigateTab(2, RECENTY_ADDED_PANEL)}>
              {VIEW_DETAILS}
            </Button>
          </div>
        }
      </>,
      uuidv4().toString(),
      blankFunc
    );
    thunkDispatch(setLoading(false));
  }
  const handleEditPanel = async (
    role: ISelectValue,
    status: string,
    projects?: ISelectValue[],
    stackLop?: ITechStackArray[]
  ) => {
    let userOnBench = false;
    if (selectedUser?.userId || selectedRows.length) {
      let data = {
        userId: selectedRows.length ? selectedRows : [selectedUser?.userId],
        roleId: role.id,
        status: status
      };
      if (role.id.toString() === shared.panel?.id.toString()) {
        const panelData = {
          projectIds: projects?.map((item) => item.id.toString()) || [],
          onBench: !projects?.length,
          techStackDetails: stackLop ? stackLop : []
        };
        if (panelData.onBench) {
          userOnBench = true;
        }
        Object.assign(data, panelData);
      }
      await thunkDispatch(editUserInfo(data));

      setPanelState((prevState) => ({
        ...prevState,
        isSaveOn: false,
        isEditingOn: false,
        isToastVisible: status !== selectedUser.statusEnum && true,
        selectedRows: [],
        isSearchOptionSelected: false,
        clearResult: true
      }));
      const toastMsgApproved = `${SUCCESSFULLY_APPROVED} ${
        selectedRows.length < 1
          ? selectedUser?.firstName + ' ' + selectedUser?.lastName
          : selectedRows.length + ' ' + 'users'
      }`;
      const toastMsgOnboard = `${SUCCESSFULLY_ONBOARDED} ${
        selectedRows.length < 1
          ? selectedUser?.firstName + ' ' + selectedUser?.lastName
          : selectedRows.length + ' ' + 'users'
      }`;
      const toastMsgInReview = `${SUCCESSFULLY_ARCHIEVED} ${
        selectedRows.length < 1
          ? selectedUser?.firstName + ' ' + selectedUser?.lastName
          : selectedRows?.length + ' ' + 'users'
      }`;
      if (status !== selectedUser.statusEnum) {
        notify(
          true,
          <>
            {
              <div className="toast-container-detail">
                <Typography customStyle="toast-title">
                  {status === STATUSENUM_APPROVED
                    ? toastMsgApproved
                    : status === STATUSENUM_IN_REVIEW
                    ? toastMsgOnboard
                    : toastMsgInReview}
                </Typography>
                <Button
                  variant="outlined"
                  customStyle="toast-detail-button"
                  onClick={handleNavigateTab(
                    status === STATUSENUM_APPROVED ? 1 : status === STATUSENUM_IN_REVIEW ? 0 : 2,
                    RECENTY_ADDED_PANEL,
                    selectedUser && selectedUser?.userId
                  )}>
                  {VIEW_DETAILS}
                </Button>
              </div>
            }
          </>,
          uuidv4().toString(),
          blankFunc
        );
      } else if (userOnBench && selectedUser?.projects?.length) {
        notify(
          true,
          <>
            {
              <div className="toast-container-detail">
                <Typography customStyle="toast-title">
                  {`${ON_BENCH_LABEL} ${selectedUser?.firstName} ${selectedUser?.lastName} on bench`}
                </Typography>
              </div>
            }
          </>,
          uuidv4().toString(),
          blankFunc
        );
      }
      handleTabChange({ tab: tabButtonValue });

      setMultiOnboard(false);
      handlCloseDrawer && handlCloseDrawer();
      return;
    }
  };
  const handleMultiDelete = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isDeleteOn: true
    }));
  };
  const handleDelete = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isSingleDeleteOn: true
    }));
  };
  const handleCloseModal = () => {
    setNonDeletedUserModal(false);
    setPanelState((prevState) => ({ ...prevState, selectedRows: [] }));
    handleTabChange({ tab: tabButtonValue });
  };

  const handleOnboard = (userId: string) => {
    commonTdClick(userId);
    setHideArchive(true);
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: true,
      selectedRows: []
    }));
  };
  const handleMultiOnboard = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isEditingOn: true
    }));
    setMultiOnboard(true);
  };
  function onUndoDelete(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const id = event.currentTarget.id;
    toast.dismiss(id);
    setPanelState((prev) => ({
      ...prev,
      isSearchOptionSelected: false,
      clearResult: true
    }));
    toast.update(id, { onClose: () => {}, hideProgressBar: true });
    getPanelSpecific(filtersBody);
  }

  const getSelectedRows = (param: string[]) => {
    setPanelState((prevState) => ({
      ...prevState,
      selectedRows: [...param]
    }));
  };
  const getPageNo = (param: number) => {
    setPanelState((prevState) => ({
      ...prevState,
      pageNo: param,
      currentPg: param
    }));
  };

  const handleCloseSort = useCallback(() => {
    if (isSortingOn) {
      setPanelState((prevState) => ({
        ...prevState,
        isSortingOn: false
      }));
    }
  }, [isSortingOn]);
  const handleOnFilterClose = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isFilteringOn: !isFilteringOn
    }));
  };

  const handleSetfilterCount = (param: number) => {
    setPanelState((prevState) => ({
      ...prevState,
      filterCount: param
    }));
  };
  const handleConfirmDelete = () => {
    deleteConfirmationClick();
    setPanelState((prevState) => ({
      ...prevState,
      isSingleDeleteOn: false
    }));
  };
  const handleConfirmCloseDelete = () =>
    setPanelState((prevState) => ({
      ...prevState,
      isDeleteOn: false
    }));

  const handleCloseSingleDelete = () =>
    setPanelState((prevState) => ({
      ...prevState,
      isSingleDeleteOn: false
    }));
  const handleSortingClose = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isSortingOn: false
    }));
  };
  const handleSortOn = () => {
    setPanelState((prevState) => ({
      ...prevState,
      isFilteringOn: false,
      isSortingOn: !isSortingOn
    }));
  };
  const handleDrawerOnLoad = () => {
    const locationState = openStatusDrawer?.state as unknown as any;
    if (locationState?.id) {
      setPanelState((prev) => ({
        ...prev,
        filtersBody: { ...panelState?.filtersBody, sortBy: RECENTY_ADDED_PANEL }
      }));
      commonTdClick(locationState.id.toString());
    } else if (locationState?.status) {
      setPanelState((prev) => ({
        ...prev,
        filtersBody: { ...panelState?.filtersBody, sortBy: RECENTY_ADDED_PANEL }
      }));
    }
    navigate(location.pathname, { replace: true });
  };

  const columns: Column<any>[] = [
    {
      Header: (
        <div key={NAME} className="panel-header-cell ap-name-header-cell">
          Name
        </div>
      ),
      accessor: 'firstName',
      Cell: (item) => {
        return (
          <div
            key={NAME}
            className="panel-row-cell"
            onClick={() => commonTdClick(item?.row?.original?.id)}>
            <RowCreator
              component={NAME}
              panel={item?.row?.original}
              showOption={isEditingOn}
              tooltip={true}
            />
          </div>
        );
      }
    },
    {
      Header: (
        <div key={TECH} className="panel-header-cell">
          Tech Stack - Level of Panel
        </div>
      ),
      accessor: 'techStackInterviewLevelSet',
      Cell: (item) => {
        return (
          <div
            key={TECH}
            className="panel-row-cell panel-cell-tech-stack"
            onClick={() => commonTdClick(item?.row?.original?.id)}>
            <RowCreator
              component={TECH}
              panel={item?.row?.original}
              showLOP={true}
              showOption={isEditingOn}
              tooltip={true}
            />
          </div>
        );
      }
    },
    {
      Header: (
        <div key={EXP} className="panel-header-cell">
          Experience
        </div>
      ),
      accessor: 'totalExperience',
      Cell: (item) => {
        return (
          <div
            key={EXP}
            className="panel-row-cell panel-cell-tech-stack"
            onClick={() => commonTdClick(item?.row?.original?.id)}>
            <RowCreator
              component={EXP}
              panel={item?.row?.original}
              showOption={isEditingOn}
              tooltip={true}
            />
          </div>
        );
      }
    },
    {
      Header: (
        <div key={APPROVE_TAB} className="panel-header-cell admin-project-td">
          Project
        </div>
      ),
      accessor: 'projects',
      Cell: (item) => {
        return (
          <div
            key={PROJECT_TAB}
            className="panel-row-cell"
            onClick={() => commonTdClick(item?.row?.original?.id)}>
            <RowCreator
              component={PROJECT_TAB}
              panel={item?.row?.original}
              showOption={isEditingOn}
              tooltip={true}
            />
          </div>
        );
      }
    },
    {
      Header: (
        <div key={STATUS} className="panel-header-cell">
          {tabButtonValue !== 2 && 'Status'}
        </div>
      ),
      accessor: 'statusEnum',
      Cell: useCallback(
        (item: { row: { original: { id: string } } }) => {
          return (
            <div key={STATUS} className="panel-row-cell status-cell">
              <RowCreator
                component={STATUS}
                panel={item?.row?.original}
                handleChange={handleApproveModalOpen}
                value={statusValue}
                options={statusOptions}
                handleOnboard={handleOnboard}
                showOption={isEditingOn}
                tooltip={true}
              />
            </div>
          );
        },
        [panelState.panelDataRecords, isEditingOn]
      )
    }
  ];
  const handleWindowsState = () => () => {
    window.history.replaceState({}, document.title);
  };
  const handleSearchBlur = () =>
    setPanelState((prev) => ({
      ...prev,
      clearResult: true,
      searchedOptions: []
    }));
  const fetchData = async () => {
    thunkDispatch(setLoading(true));
    handleTabChange({ tab: tabButtonValue, emptySelectedRows: false });
    thunkDispatch(setLoading(false));
  };
  const handlePanelRecords = () => {
    setPanelState((prev) => ({
      ...prev,
      panelDataRecords: panelData?.panelList
    }));
  };

  useEffect(() => {
    handlePanelRecords();
  }, [panelData?.panelList]);

  useEffect(() => {
    fetchData();
  }, [panelState.filtersBody?.sortBy, panelState?.currentPg]);

  useEffect(handleWindowsState, []);

  useEffect(() => {
    handleDrawerOnLoad();
  }, [openStatusDrawer]);

  useEffect(() => {
    setPanelState((prev) => ({
      ...prev,
      isSearchOptionSelected: false,
      clearResult: true
    }));
  }, [tabButtonValue, isSearchOptionSelected]);
  return !panelData.isError ? (
    <div className="admin-panel-outer-container">
      <div className="ap-top-bar-wrapper-main">
        <div className="admin-panel-top-bar">
          <Typography customStyle="admin-panel-heading">Panel</Typography>
          <div className="admin-panel-filters-container">
            <div className="filter-modal-btn-container">
              <Button
                variant={OUTLINED}
                size={MEDIUM}
                customStyle={`admin-panel-filter-buttons ${isFilteringOn && 'active-btn'}`}
                onClick={() => {
                  setPanelState((prevState) => ({
                    ...prevState,
                    isFilteringOn: !isFilteringOn
                  }));
                }}>
                <ImageComponent src={filter} />
              </Button>
              {isFilteringOn && (
                <div
                  onClick={() => {
                    setPanelState((prevState) => ({
                      ...prevState,
                      isFilteringOn: !isFilteringOn
                    }));
                  }}
                  className="transparent-backdrop"></div>
              )}
              <CSSTransition in={isFilteringOn} timeout={200} unmountOnExit classNames="alert">
                <Filters
                  isOpen={isFilteringOn}
                  onFilterClose={handleOnFilterClose}
                  setFilterCount={handleSetfilterCount}
                  appliedFilters={filters}
                  applyFilters={applyFilters}
                  tab={tabButtonValue}
                />
              </CSSTransition>
              {filterCount ? <div className="ap-filter-count">{filterCount}</div> : null}
            </div>

            <div className="sort-btn-container">
              <Button
                variant={OUTLINED}
                size={MEDIUM}
                customStyle={`admin-panel-filter-buttons ${isSortingOn && 'active-btn'}`}
                onClick={handleSortOn}>
                <ImageComponent src={sortIcon} />
              </Button>
              {isSortingOn && (
                <div
                  onClick={() => {
                    setPanelState((prevState) => ({
                      ...prevState,
                      isSortingOn: !isSortingOn
                    }));
                  }}
                  className="transparent-backdrop"></div>
              )}

              <ClickAwayListener handleClose={handleCloseSort}>
                <div className="sorting-parent-container">
                  <SortingOptions
                    currentValue={sortingValue}
                    setCurrentValue={selectSort}
                    labelArray={[RECENTLY_ADDED, NAME_A_to_Z, NAME_Z_to_A, EXP_L_TO_H, EXP_H_TO_L]}
                    isOpen={isSortingOn}
                    onClose={handleSortingClose}
                  />
                </div>
              </ClickAwayListener>
            </div>
          </div>
        </div>
        <div className="ap-search-bar">
          <Search
            options={searchedOptions}
            placeholder={PANEL_SEARCH_PLACEHOLDER}
            onChange={onSearchChange}
            setSelectedId={setSearchedOption}
            customDropdownIconClass="serached-options-image"
            clearResult={clearResult}
            resultNotFound={noResultFound}
          />
        </div>
      </div>
      <div className="ap-btn-group-container" onFocus={handleSearchBlur}>
        <ButtonGroup
          orientation="horizontal"
          customStyle="admin-panel-button-group"
          activeButtonClick={handleButtonGroupClick}
          tabValue={tabButtonValue}
          showSelectedButton={true}
          options={[
            {
              size: MEDIUM,
              variant: CONTAINED,
              label: IN_REVIEW_TAB
            },
            {
              size: MEDIUM,
              variant: CONTAINED,
              label: APPROVED_TAB
            },
            {
              size: MEDIUM,
              variant: CONTAINED,
              label: ARCHIEVED_TAB_VALUE
            }
          ]}></ButtonGroup>
        <GetLDeleteModalEdgeCase
          handleCloseModalDelete={handleCloseModal}
          nonDeletedUserArray={nonDeletedUserArray}
          isOpen={nonDeletedUserModal}
        />
        {selectedRows.length <= 1 && (
          <div className="dropdown-status">
            <CustomSelect
              selectedOptions={projectStatusValue}
              dropdownOptions={projectStatusOptions}
              onChange={handleProjectStatusChange}
              showDropdownIndicator
              isSearchable={false}
              customStyles={normalStyle}
              searchOption={isEditingOn}
            />
          </div>
        )}
        {selectedRows?.length > 1 && (
          <ButtonGroups
            selectedRows={selectedRows}
            tabButtonValue={tabButtonValue}
            handleApproveModalOpen={handleApproveModalOpen}
            handleArchiveUsers={handleArchiveUsers}
            handleMultiOnboard={handleMultiOnboard}
            handleMultiDelete={handleMultiDelete}
            markOnBench={markOnBench}
            projectStatusValue={projectStatusValue?.id}
          />
        )}
        <ConfirmationModal
          title={DELETE_PANEL}
          description={DELETE_CONFIRMATION_TEXT}
          isOpen={isDeleteOn}
          onConfirm={handleConfirmDelete}
          onClose={handleConfirmCloseDelete}
          cancelText={CANCEL}
          confirmText={DELETE}
        />

        <ConfirmationModal
          isOpen={approveStatusModal}
          title={APPROVE_USER_MODAL_TITLE}
          description={approveModalData}
          onConfirm={handleApproveStatus}
          onClose={handleCloseApproveModal}
          cancelText={CANCEL}
          confirmText={APPROVE_BTN_TEXT}
        />
      </div>
      {panelData.loading ? (
        <Loader loading={panelData.loading} customClass="panel-loader" />
      ) : (
        <Table
          columnsDefinition={columns}
          rowsDefinition={panelState?.panelDataRecords}
          numberOfPages={Math.ceil(panelData.allCount / 10)}
          selectedRows={selectedRows}
          onSelectedRowChange={getSelectedRows}
          onPageChange={getPageNo}
          isCheckEnabled={true}
          currentPg={panelState.currentPg}
          isFilterScreen={isNoFilterScreen}
          totalCount={panelData.allCount}
        />
      )}
      <ConfirmationModal
        title={DELETE_PANEL}
        description={DELETE_SINGLE_USER}
        isOpen={isSingleDeleteOn}
        onConfirm={deleteConfirmationClick}
        onClose={handleCloseSingleDelete}
        cancelText={CANCEL}
        confirmText={DELETE}
      />
      <Drawer
        isOpen={isEditingOn}
        onClose={handlCloseDrawer}
        bodyStyles="modal-drawer-body"
        headerProps={
          <>
            {multiOnboard ? (
              <div>
                <Typography customStyle="side-modal-label">
                  {selectedRows.length > 1 ? MASS_ONBOARDING_HEADING : ONBOARDING_HEADING}
                </Typography>
              </div>
            ) : id === selectedUser?.userId ? (
              ''
            ) : (
              <div
                className="delete-icon"
                tabIndex={0}
                onKeyDown={handleTabEventwithoutValue(handleDelete)}>
                <ImageComponent src={deleteIcon} onClick={handleDelete} />
              </div>
            )}
          </>
        }>
        {panelData?.isDrawerLoading ? (
          <div className="drawer-loader">
            <LoaderSpinner className="loading-spinner" />
          </div>
        ) : (
          <PanelDrawer
            multiOnboard={multiOnboard}
            selectedPanel={selectedUser}
            panelState={panelState}
            hideArchive={hideArchive}
            id={id}
            panel={panel!}
            handlCloseDrawer={handlCloseDrawer}
            handleEditPanel={handleEditPanel}
          />
        )}
      </Drawer>
    </div>
  ) : (
    <ErrorPage page="apiFailure" />
  );
}

export default routeError(Panel);
