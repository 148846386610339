import React, { Fragment, useCallback, useEffect } from 'react';
import './index.style.scss';
import Drawer from 'app/core/shared-components/drawer';
import Typography from 'app/core/shared-components/typography';
import SlotRequestCard from 'app/core/shared-components/slot-request-drawer/slot-request-card';
import { useAppSelector, useAppThunkDispatch } from 'app/redux/hooks';
import {
  addSlotRequest,
  getSlotRequest,
  ISlotRequest,
  removeSlotRequest,
  slotDeclineRequest,
  slotRequestAccept
} from 'app/redux/slices/scheduleSlice';
import Loader from 'app/core/shared-components/loader';
import ImageComponent from 'app/core/shared-components/image';
import Images from 'assets/images';
import { SLOT_REQUEST_ACCEPTED, SLOT_REQUEST_DECLINED } from 'helpers/messages.constants';
import { notify } from 'helpers/toastHelper';
import ToastUndo from '../toast-undo';
import { toast } from 'react-toastify';
import { getAccessToken } from 'helpers/authHelper';
import ServerSentEvent from 'helpers/eventSource';

interface ISlotRequestDrawerProps {
  open: boolean;
  onClose: () => void;
  onAccept?: (request: string | number) => void;
  onDeclined?: (request: string | number) => void;
}

function SlotRequestDrawer(props: ISlotRequestDrawerProps) {
  const reduxScheduleState = useAppSelector((state) => state.schedule);
  const dispatch = useAppThunkDispatch();
  const { slotRequests, slotRequestLoading } = reduxScheduleState;

  const handleGetSlotRequests = useCallback((_event: MessageEvent) => {
    dispatch(getSlotRequest());
  }, []);

  const handleSlotRequestUndo = useCallback(
    (slotRequestId: string | number, type: 'ACCEPT' | 'DECLINE') => () => {
      dispatch(addSlotRequest({ slotRequestId, type }));
      toast.update(slotRequestId, { onClose: () => {}, hideProgressBar: true, autoClose: 1 });
      toast.dismiss(slotRequestId);
    },
    []
  );

  const handleSlotRequestAccept = useCallback(
    async (slotRequestId: string | number) => {
      const { onAccept } = props;
      const response = await dispatch(slotRequestAccept(slotRequestId));
      if (response.payload.data && onAccept) {
        onAccept(slotRequestId);
      }
    },
    [props.onAccept]
  );

  const handleSlotRequestDecline = useCallback(async (slotRequestId: string | number) => {
    const { onDeclined } = props;
    const response = await dispatch(slotDeclineRequest(slotRequestId));
    if (response.payload && onDeclined) {
      onDeclined(slotRequestId);
    }
  }, []);

  const handleRequestAccept = (request: ISlotRequest) => () => {
    dispatch(removeSlotRequest({ slotRequestId: request.slotRequestId, type: 'ACCEPT' }));
    notify(
      false,
      <ToastUndo
        toastId={request.slotRequestId}
        toastMessage={SLOT_REQUEST_ACCEPTED}
        handleUndo={handleSlotRequestUndo(request.slotRequestId, 'ACCEPT')}
      />,
      request.slotRequestId,
      handleSlotRequestAccept
    );
  };

  const handleRequestDecline = (request: ISlotRequest) => () => {
    dispatch(removeSlotRequest({ slotRequestId: request.slotRequestId, type: 'DECLINE' }));
    notify(
      false,
      <ToastUndo
        toastId={request.slotRequestId}
        toastMessage={SLOT_REQUEST_DECLINED}
        handleUndo={handleSlotRequestUndo(request.slotRequestId, 'DECLINE')}
      />,
      request.slotRequestId,
      handleSlotRequestDecline
    );
  };

  const handleEventSource = () => {
    const token = getAccessToken();
    const encodeToken = encodeURIComponent(token);
    const eventSource = new ServerSentEvent(
      `${process.env.REACT_APP_API_URL}/panel/slot/request/push/notification?authToken=${encodeToken}`
    );
    eventSource.onMessage = handleGetSlotRequests;
    eventSource.openConnection();
    return () => {
      eventSource?.closeConnection();
    };
  };

  useEffect(() => {
    handleEventSource();
  }, []);

  return (
    <Drawer
      isOpen={props.open}
      headerProps={<DrawerHeader slotCount={slotRequests.length} />}
      bodyStyles="slot-request-drawer-body"
      onClose={props.onClose}>
      {!slotRequestLoading ? (
        <Fragment>
          {!!slotRequests.length ? (
            slotRequests.map((item) => (
              <SlotRequestCard
                key={item.slotRequestId}
                slotDateStart={new Date(item.fromTime)}
                slotDateEnd={new Date(item.toTime)}
                imageSource={item.profileImageUrl}
                name={`${item.firstName} ${item.lastName}`}
                techStack={item.techStack}
                level={item.level}
                onAccept={handleRequestAccept(item)}
                onDecline={handleRequestDecline(item)}
              />
            ))
          ) : (
            <div className="slot-request-placeholder">
              <ImageComponent src={Images.noResult} alt="no-result" customClass="no-result-image" />
              <Typography customStyle="no-result-text">No slot requests</Typography>
            </div>
          )}
        </Fragment>
      ) : (
        <Loader loading={slotRequestLoading} customClass="loader-wrapper" />
      )}
    </Drawer>
  );
}

interface ISlotRequestDrawerHeaderProps {
  slotCount: number;
}

function DrawerHeader(props: ISlotRequestDrawerHeaderProps) {
  return (
    <div className="slot-drawer-header">
      <Typography customStyle="header-label">Slot Requests</Typography>
      <Typography customStyle="header-label-count">{`(${props.slotCount})`}</Typography>
    </div>
  );
}

export default SlotRequestDrawer;
